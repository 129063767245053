import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { SEO } from "gatsby-theme-core";
import { Layout, HeaderOne, FooterOne } from "gatsby-theme-layout";
import {
    VideoStreamingContainerTwo,
    HeroSection03,
    BannerSection3,
} from "gatsby-theme-container";
import { normalizedData } from "@streamo/helper/methods";

const StreamingIndex = ({ data }) => {
    const content = normalizedData(data?.page?.content || []);
    return (
        <Layout>
            <SEO pageName="Series Page" />
            <HeaderOne data={{ ...data?.header, ...data.navigation }} />
            <HeroSection03
                data={{
                    items: data?.featuredSeriesPost?.nodes,
                }}
            />
            <VideoStreamingContainerTwo
                data={{
                    ...content?.["horror-series-section"],
                    items: data?.videoStreamingHorrorSeries?.nodes,
                }}
            />
            <BannerSection3
                data={{
                    items: data?.romanticSeriesBanner?.nodes,
                }}
            />
            <VideoStreamingContainerTwo
                data={{
                    ...content?.["romantic-series-section"],
                    items: data?.romanticSeriesSeries?.nodes,
                }}
            />
            <BannerSection3
                data={{
                    items: data?.videoStreamingComedySeriesBanner?.nodes,
                }}
            />
            <VideoStreamingContainerTwo
                data={{
                    ...content?.["comedy-series-section"],
                    items: data?.videoStreamingComedySeries?.nodes,
                }}
            />
            <FooterOne data={{ ...data?.footer, ...data?.contact }} />
        </Layout>
    );
};

export const query = graphql`
    query StreamingSeriesTowQuery {
        header: general(section: { eq: "header-01" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu-streaming" }) {
            ...Menu01
        }
        footer: general(section: { eq: "footer-01" }) {
            ...Footer01
        }
        contact: general(section: { eq: "contact" }) {
            ...Contact01
        }
        page(title: { eq: "streaming-series" }) {
            content {
                ...PageContent
            }
        }
        featuredSeriesPost: allVideoStreamingSeries(
            filter: {
                category: { elemMatch: { slug: { eq: "horror" } } }
                is_featured: { eq: true }
            }
        ) {
            nodes {
                ...VideoStreamingSeries
            }
        }
        videoStreamingHorrorSeries: allVideoStreamingSeries(
            sort: { fields: date, order: DESC }
            filter: { category: { elemMatch: { slug: { eq: "horror" } } } }
        ) {
            nodes {
                ...VideoStreamingSeries
            }
        }
        romanticSeriesBanner: allVideoStreamingSeries(
            filter: {
                category: { elemMatch: { slug: { eq: "romantic" } } }
                is_featured: { eq: true }
            }
        ) {
            nodes {
                ...VideoStreamingSeries
            }
        }
        romanticSeriesSeries: allVideoStreamingSeries(
            filter: { category: { elemMatch: { slug: { eq: "romantic" } } } }
        ) {
            nodes {
                ...VideoStreamingSeries
            }
        }
        videoStreamingComedySeriesBanner: allVideoStreamingSeries(
            filter: {
                category: { elemMatch: { slug: { eq: "comedy" } } }
                is_featured: { eq: true }
            }
        ) {
            nodes {
                ...VideoStreamingSeries
            }
        }
        videoStreamingComedySeries: allVideoStreamingSeries(
            filter: { category: { elemMatch: { slug: { eq: "comedy" } } } }
        ) {
            nodes {
                ...VideoStreamingSeries
            }
        }
    }
`;

StreamingIndex.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.shape({}),
        navigation: PropTypes.shape({}),
        footer: PropTypes.shape({}),
        contact: PropTypes.shape({}),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        featuredSeriesPost: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        videoStreamingHorrorSeries: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        romanticSeriesBanner: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        romanticSeriesSeries: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        videoStreamingComedySeriesBanner: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        videoStreamingComedySeries: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export default StreamingIndex;
